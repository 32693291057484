(function () {
  ("use strict");
  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
      if (all) {
        selectEl.forEach((e) => e.addEventListener(type, listener));
      } else {
        selectEl.addEventListener(type, listener);
      }
    }
  };

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
  };

  /**
   * Back to top button
   */
  let backtotop = select(".back-to-top");
  let apple = select(".back-to-top.apple");
  let google = select(".back-to-top.google");
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    };
    window.addEventListener("load", toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }
  if (google) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        google.classList.add("active");
      } else {
        google.classList.remove("active");
      }
    };
    window.addEventListener("load", toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }
  if (apple) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        apple.classList.add("active");
      } else {
        apple.classList.remove("active");
      }
    };
    window.addEventListener("load", toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }

  /**
   * Preloader
   */
  let preloader = select("#preloader");
  if (preloader) {
    window.addEventListener("load", () => {
      preloader.remove();
    });
  }

  /**
   * SLIDERS
   */

  window.addEventListener("load", () => {
    let index = 1;
    let interval = 3000;
    //let timeout = 000;
    // setTimeout(() => {
    if (select("#hero")) {
      select("#p1").classList.remove("d-none");
      select("#p1-1").classList.add("slide-right");
      select("#p1-2").classList.add("slide-left");
      select("#p2").classList.add("d-none");
      select("#p3").classList.add("d-none");
      select("#p3-1").classList.add("d-none");
      select("#p4-1").classList.add("d-none");
      select("#p5-1").classList.add("d-none");
      select("#p6-1").classList.add("d-none");
      select("#p3-2").classList.add("d-none");
      select("#p4-2").classList.add("d-none");
      select("#p5-2").classList.add("d-none");
      select("#p6-2").classList.add("d-none");
      select("#p7").classList.add("d-none");
      select("#p7-1").classList.add("d-none");
      select("#p7-2").classList.add("d-none");
      setInterval(function () {
        switch (index) {
          case 1:
            select("#p1").classList.add("d-none");
            select("#p2").classList.remove("d-none");
            index++;
            break;
          case 2:
            select("#p2").classList.add("d-none");
            select("#p3").classList.remove("d-none");
            select("#p3-1").classList.remove("d-none");
            select("#p3-2").classList.remove("d-none");
            index++;
            break;
          case 3:
            select("#p3-1").classList.add("d-none");
            select("#p3-2").classList.add("d-none");
            select("#p4-1").classList.remove("d-none");
            select("#p4-2").classList.remove("d-none");
            index++;
            break;
          case 4:
            select("#p4-1").classList.add("d-none");
            select("#p4-2").classList.add("d-none");
            select("#p5-1").classList.remove("d-none");
            select("#p5-2").classList.remove("d-none");
            index++;
            break;
          case 5:
            select("#p5-1").classList.add("d-none");
            select("#p5-2").classList.add("d-none");
            select("#p6-1").classList.remove("d-none");
            select("#p6-2").classList.remove("d-none");
            index++;
            break;
          case 6:
            select("#p3").classList.add("d-none");
            select("#p6-1").classList.add("d-none");
            select("#p6-2").classList.add("d-none");
            select("#p7").classList.remove("d-none");
            select("#p7").classList.add("zoom-in");
            select("#p7-1").classList.remove("d-none");
            select("#p7-1").classList.add("slide-bottom");
            select("#p7-2").classList.remove("d-none");
            select("#p7-2").classList.add("slide-top");
            index++;
            break;
          case 7:
            //do nothing
            index++;
            break;

          default:
            index = 1;
            select("#p7").classList.add("d-none");
            select("#p7").classList.remove("zoom-in");
            select("#p7-1").classList.add("d-none");
            select("#p7-1").classList.remove("slide-bottom");
            select("#p7-2").classList.add("d-none");
            select("#p7-2").classList.remove("slide-top");
            select("#p1").classList.remove("d-none");
            break;
        }
      }, interval);
    }
    // }, timeout);
  });
  /**
   * TWISTR COOKIES
   */
  window.addEventListener("load", () => {
    var cookieValue = getCookie("TWISTR-COOKIES");
    console.log("cookieValue", cookieValue);
    var main = select("body");
    var year = select("#year");
    var cookieSettingsTab = select("#cookieSettingsTab");
    var cookiesContainer = select("#cookiesContainer");
    if (cookieValue == "0" || cookieValue != "") {
      if (cookiesContainer) cookiesContainer.classList.add("d-none");
    } else {
      if (cookiesContainer) cookiesContainer.classList.remove("d-none");
    }
    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }

    // Set default consent to 'denied' as a placeholder
    // Determine actual values based on your own requirements
    gtag("consent", "default", {
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      analytics_storage: "denied",
    });
    if (cookieValue == "0" || cookieValue == "") {
      disableGoogle("", "");
    } else {
      enableGoogle("", "");
    }

    if (year) select("#year").innerText = new Date().getFullYear();
    if (main) {
      on("click", "#cookieSettingsTab", function (e) {
        if (cookiesContainer) cookiesContainer.classList.remove("d-none");
      });
      on("click", "#cookieSettingsTab1", function (e) {
        if (cookiesContainer) cookiesContainer.classList.remove("d-none");
      });

      on("click", "#allowCookieBtn", function (e) {
        if (cookieValue == "" || cookieValue == "0") {
          cookieValue = Date.now();
          console.log(cookieValue);
        }
        enableGoogle(cookieValue, "");
        if (cookiesContainer) cookiesContainer.classList.add("d-none");
      });

      on("click", "#declineCookieBtn", function (e) {
        disableGoogle(cookieValue, "");
        if (cookiesContainer) cookiesContainer.classList.add("d-none");
      });
      var generate = generateCaptcha();
      var generated_captcha = select("#generated-captcha");
      if (generated_captcha) {
        generated_captcha.innerHTML = generate;
        select("#g-captcha").value = generate;
        select("#captcha").value = "";
      }
    }
  });
  /**
   * Animation on scroll
   */
  window.addEventListener("load", () => {
    var fadeElements = document.getElementsByClassName("scrollFade");

    function scrollFade() {
      var viewportBottom = window.scrollY + window.innerHeight;

      for (var index = 0; index < fadeElements.length; index++) {
        var element = fadeElements[index];
        var rect = element.getBoundingClientRect();

        var elementFourth = rect.height / 4;
        var fadeInPoint = window.innerHeight - elementFourth;
        var fadeOutPoint = -(rect.height / 2);

        if (rect.top <= fadeInPoint) {
          element.classList.add("scrollFade--visible");
          element.classList.add("scrollFade--animate");
          element.classList.remove("scrollFade--hidden");
          element.classList.remove("scrollFade");
        } else {
          element.classList.remove("scrollFade--visible");
          element.classList.add("scrollFade--hidden");
        }
      }
    }

    document.addEventListener("scroll", scrollFade);
    window.addEventListener("resize", scrollFade);
    document.addEventListener("DOMContentLoaded", function () {
      scrollFade();
    });
  });
  /**
   * Redirect download button
   */
  window.addEventListener("load", () => {
    var downloadNow1 = select(`#downloadNow1`);
    var downloadNow2 = select(`#downloadNow2`);
    var downloadNow3 = select(`#downloadNow3`);
    if (downloadNow1 || downloadNow2 || downloadNow3 || appStore || googlePlay) {
      var appStore = select(`#appStore`);
      var googlePlay = select(`#googlePlay`);
      var linkButton_appStore = select(`.linkButton-appStore`);
      var linkButton_googlePlay = select(`.linkButton-googlePlay`);
      var apple = "https://apple.co/3xeKUh6";
      var storeredirect = "/storeredirect";
      var google = "https://play.google.com/store/apps/details?id=com.twistrapp";
      if (device.mobile()) {
        if (device.ios()) {
          if (downloadNow1) downloadNow1.href = apple;
          if (downloadNow2) downloadNow2.href = apple;
          if (downloadNow3) downloadNow3.href = apple;
          if (appStore) appStore.classList.remove("d-none");
          if (googlePlay) googlePlay.classList.add("d-none");
          if (linkButton_appStore) {
            linkButton_appStore.click();
            // window.close();
          }
        } else if (device.android()) {
          if (downloadNow1) downloadNow1.href = google;
          if (downloadNow2) downloadNow2.href = google;
          if (downloadNow3) downloadNow3.href = google;
          if (appStore) appStore.classList.add("d-none");
          if (googlePlay) googlePlay.classList.remove("d-none");
          if (linkButton_googlePlay) {
            linkButton_googlePlay.click();
            // window.close();
          }
        }
      } else {
        if (downloadNow1) downloadNow1.href = storeredirect;
        if (downloadNow2) downloadNow2.href = storeredirect;
        if (downloadNow3) downloadNow3.href = storeredirect;
        if (appStore) appStore.classList.remove("d-none");
        if (googlePlay) googlePlay.classList.remove("d-none");
      }
    }
  });
})();

function allConsentGranted() {
  gtag("consent", "update", {
    ad_user_data: "granted",
    ad_personalization: "granted",
    ad_storage: "granted",
    analytics_storage: "granted",
  });
}
function enableGoogle(cookieValue, pageLocation) {
  if (cookieValue != "") {
    setCookie("TWISTR-COOKIES", cookieValue, 365);
    setCookieDB(cookieValue, true, pageLocation);
  }
  allConsentGranted();
  window["ga-disable-G-41S8M2FPVZ"] = false;
}

function setCookieDB(cookieValue, isTrue, pageLocation) {
  console.log("pageLocation",pageLocation);
  var url;
  if (pageLocation === "redirect2") {
    url = "../../users/setcookie";
  } else {
    url = "/users/setcookie";
  }
  const data = {
    cookieValue: cookieValue + "",
    isTrue,
  };

  fetch(url, {
    method: "POST", // or 'PUT'
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

function disableGoogle(cookieValue, pageLocation) {
  if (cookieValue != "" && cookieValue !== "0") {
    setCookie("TWISTR-COOKIES", "0", 365);
    setCookieDB(cookieValue, false, pageLocation);
  }
  window["ga-disable-G-41S8M2FPVZ"] = true;
  gtag("consent", "update", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
  });
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  console.log(ca);
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      console.log(c.substring(name.length, c.length));
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function generateCaptcha() {
  let e = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz",
    s = e[Math.floor(Math.random() * e.length)],
    o = Math.floor(10 * Math.random()),
    n = Math.floor(10 * Math.random()),
    t = e[Math.floor(Math.random() * e.length)],
    a = e[Math.floor(Math.random() * e.length)],
    d = Math.floor(10 * Math.random());
  return s.toString() + o.toString() + n.toString() + t.toString() + a.toString() + d.toString();
}
// <!-- Google Analytics -->
(function (i, s, o, g, r, a, m) {
  i["GoogleAnalyticsObject"] = r;
  (i[r] =
    i[r] ||
    function () {
      (i[r].q = i[r].q || []).push(arguments);
    }),
    (i[r].l = 1 * new Date());
  (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
})(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

// <!-- End Google Analytics -->
